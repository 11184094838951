
  export const style={
    inpuStyle:{
      colorInput:"#222222"
    },
    url:{
      urlClient:"https://pelando.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.us-east-1.amazonaws.com/Logos/secondary_logo_client_pelando.png",
      secondaryLogonLogo: "null",
    },
    variables:{
       customMenuItem: false,
       autoRegister: false,
       recaptchaVersion : "V1"
    },
    CurrencyName:{
      name:"Foguinho/Foguinhos"
    },
    teamName:{
      name:"pelando"
    },
    hasToken: false,
    hostHeader: "pelando"
  }
